import React, { useEffect, useState } from 'react';
import { Column, HeaderGroup, useSortBy, useTable, usePagination } from 'react-table';
import { Button, Card, CardBody, Table, Input, Alert, ButtonGroup } from 'reactstrap';
import { downloadBatchReport, getBatchDocStatus, getBatches, revertBatch } from '../api/document';
import { Link } from 'react-router-dom';
import { Batch } from '../types/document';
import styles from '../styles/archiveui.module.css';
import { useUserContext } from '../contexts/UserContext';
import { formatDateWithTime } from '../utils/date';
import ConfirmDelete from '../roles/roledetail/confirmDelete';
import { toast } from 'react-toastify';
import { LoadingComponent } from '../Loading';
import BatchDetail from './batchDetail';

const BatchList = (): JSX.Element => {
  const [batches, setBatches] = useState<Batch[]>([]);
  const { currentAccount } = useUserContext();
  const [revertConfirmModal, setRevertConfirmModal] = useState<boolean>(false);
  const [batchDetailModal, setBatchDetailModal] = useState<boolean>(false);
  const [batchDetail, setBatchDetail] = useState<Batch>();
  const [loading, setLoading] = useState<boolean>(false);
  const revertModalBody = 'Are you sure you want to revert this batch?';
  const revertModalHeader = 'Revert Batch';
  const toggleRevertModal = () => {
    setRevertConfirmModal((prev) => {
      return !prev;
    });
  };
  const toggleBatchDetailModal = () => {
    setBatchDetailModal((prev) => {
      return !prev;
    });
  };
  const [batchToRevert, setBatchToRevert] = useState<string>('');
  const updateBatches = async () => {
    setLoading(true);
    const loadedBatches = await getBatches(currentAccount.accountId);
    setBatches(loadedBatches);
    console.log('batches', batches);
    setLoading(false);
  };

  useEffect(() => {
    updateBatches();
  }, [currentAccount]);

  const clearBatches = () => {
    setBatches([]);
  };

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Type',
          accessor: 'type',
          Cell: (cellProps) => {
            if (cellProps.value) {
              return <span>{cellProps?.value.charAt(0).toUpperCase() + cellProps?.value.toLowerCase().substr(1)}</span>;
            }
            return null;
          },
        },
        {
          Header: 'Status',
          accessor: 'status',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            if (cellProps.value) {
              return <span>{cellProps?.value.charAt(0).toUpperCase() + cellProps?.value.toLowerCase().substr(1)}</span>;
            }
            return null;
          },
        },
        {
          Header: 'Created',
          accessor: 'created',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            return <span>{formatDateWithTime(cellProps.cell.value)}</span>;
          },
        },
        {
          Header: 'Actions',
          accessor: 'accountId',
          // eslint-disable-next-line react/display-name
          Cell: (cellProps) => {
            return (
              <>
                <Button className={styles.scrbBtnGreen} onClick={() => doBatchDetail(cellProps.row.original.id)}>
                  Detail
                </Button>
                <Button className={styles.scrbBtnBlue} onClick={() => doBatchReport(cellProps.row.original.id)}>
                  Download Report
                </Button>
                {cellProps.row.original.status !== 'OPEN' ? (
                  <>
                    <Button className={styles.scrbBtnOrange} onClick={() => beginRevertBatch(cellProps.row.original.id)}>
                      Revert
                    </Button>
                  </>
                ) : null}
              </>
            );
          },
        },
      ] as Column<Batch>[],
    [],
  );
  const data = React.useMemo(() => batches, [batches]);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0, sortBy: [{ id: 'created', desc: true }] } }, useSortBy, usePagination);

  const renderSort = (column: HeaderGroup<Batch>): JSX.Element => {
    if (column.isSorted) {
      return <span>{column.isSortedDesc ? ' 🔽' : ' 🔼'}</span>;
    }
    return <span></span>;
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  const doBatchDetail = async (batchId: string) => {
    const batch = await getBatchDocStatus(currentAccount.accountId, batchId);
    setBatchDetail(batch);
    toggleBatchDetailModal();
  };

  const doBatchReport = async (batchId: string) => {
    await toast.promise(downloadBatchReport(currentAccount.accountId, batchId), {
      pending: 'Starting report generation...',
      error: 'Unable to generate report...',
      success: 'Report is generating, you will receive an email with a download link once it completes.',
    });
  };

  const beginRevertBatch = (batchId: string) => {
    toggleBatchDetailModal();
    setRevertConfirmModal(true);
    setBatchToRevert(batchId);
  };

  const doRevertBatch = async () => {
    await toast.promise(revertBatch(currentAccount.accountId, batchToRevert), {
      success: 'Batch deletion in-progress, this may take a few minutes',
      pending: 'Starting batch deletion',
      error: 'Unable to delete batch...',
    });
    setRevertConfirmModal(false);
    setBatchToRevert('');
  };

  return (
    <LoadingComponent isLoading={loading}>
      <div>
        <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
          <CardBody className={styles.searchBarHeader}>
            <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>Document Batches</h5>
          </CardBody>
          <CardBody className={styles.searchBarBody}>
            <div className="row">
              <div className="col">
                <Button className={styles.scrbBtnBlueMargin} onClick={updateBatches}>
                  Refresh
                </Button>
                <Button className={styles.filterBtn} onClick={clearBatches}>
                  Clear
                </Button>
              </div>
              <div className="col-md-auto">
                <ButtonGroup>
                  <Button className={styles.scrbBtnOrange} tag={Link} to="/batch/scribonline">
                    Create Conversion
                  </Button>
                  <Button className={styles.scrbBtnBlue} tag={Link} to="/imagesilo">
                    ImageSilo Conversion
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            <br />
          </CardBody>
        </Card>
        {batches.length > 0 ? (
          <>
            <Card style={{ marginTop: '1em', borderRadius: '.5em' }}>
              <Table striped hover size="sm" id="auditTable" responsive>
                <thead className={styles.tableHeader}>
                  {headerGroups.map((headerGroup) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
            <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
              <div style={{ marginRight: '.5em' }}>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </Button>{' '}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </Button>{' '}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </Button>{' '}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </Button>{' '}
              </div>
              <div style={{ marginRight: '.5em' }}>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <Input
                style={{ maxWidth: '10em' }}
                type="select"
                aria-label="Select Page Size"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </div>
            <ConfirmDelete
              modal={revertConfirmModal}
              toggle={toggleRevertModal}
              onConfirm={doRevertBatch}
              bodyText={revertModalBody}
              headerText={revertModalHeader}
            />
            <BatchDetail modal={batchDetailModal} toggle={toggleBatchDetailModal} revertBatch={beginRevertBatch} batch={batchDetail} />
          </>
        ) : (
          <Alert color="warning" className={styles.noResultsAlert}>
            No batches loaded yet...
          </Alert>
        )}
      </div>
    </LoadingComponent>
  );
};

export default BatchList;
