import React from 'react';
import styles from '../styles/archiveui.module.css';
import { RefObject } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Batch } from '../types/document';

interface batchDetailProps {
  modal: boolean;
  toggle: () => void;
  revertBatch: (batchId: string) => void;
  batch?: Batch;
  ref?: RefObject<HTMLDivElement>;
  headerText?: string;
}

const BatchDetail = ({ modal, toggle, revertBatch, ref, headerText, batch }: batchDetailProps) => {
  return (
    <div ref={ref}>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{headerText ?? 'Batch Detail'}</ModalHeader>
        <ModalBody>
          {batch ? (
            <>
              <p>
                <strong>Batch ID: </strong>
                {batch.id}
              </p>
              <p>
                <strong>Status: </strong>
                {batch.status}
              </p>
              <ul>
                {Object.keys(batch.documentStatuses)
                  .sort()
                  .map((statusKey) => {
                    return (
                      <li key={statusKey}>
                        {statusKey}: {batch.documentStatuses[statusKey]}
                      </li>
                    );
                  })}
              </ul>
            </>
          ) : (
            <>Loading batch detail</>
          )}
        </ModalBody>
        <ModalFooter>
          {batch && batch.status !== 'OPEN' ? (
            <>
              <Button className={styles.scrbBtnOrange} onClick={() => revertBatch(batch.id)}>
                Revert
              </Button>
            </>
          ) : null}
          <Button className={styles.scrbBtnBlue} onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BatchDetail;
